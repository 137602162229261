<template>
    <span>
        <v-icon v-if="direction" small >
            {{ direction === "DESC" ? mdiChevronDown  :  mdiChevronUp }}
        </v-icon>
        <v-icon v-else small >
            {{ mdiUnfoldMoreHorizontal   }}
        </v-icon>
    </span>
</template>

<script>
import { mdiUnfoldMoreHorizontal, mdiChevronUp, mdiChevronDown } from '@mdi/js'

export default {
  name: 'SortingArrows',
  props: {
    direction: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {
      mdiUnfoldMoreHorizontal,
      mdiChevronUp,
      mdiChevronDown,
    }
  },
}
</script>

<template>
  <!-- <v-progress-circular
    indeterminate
    color="primary"
  ></v-progress-circular> -->
   <v-progress-linear
      indeterminate
      color="primary darken-2"
    ></v-progress-linear>
  <!-- <v-card
    color="primary"
    dark
    width="300"
    class="loader"
  >
    <v-card-text class="pt-3">
      Loading...
      <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
      ></v-progress-linear>
    </v-card-text>
  </v-card> -->
</template>

<script>
export default {
  name: 'Loading',
}
</script>

<style scoped>
.loader {
  /* position: fixed; */
  left: 40%;
  top: 50%;
  z-index: 9999;
}
</style>
